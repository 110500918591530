
    import { Component, Vue } from "vue-property-decorator";

    @Component
    export default class SocialNetworkHeader extends Vue
    {
        get getUrlFacebook(): string
        {
            return this.translateValue(this.$tStore.state.configState.setting.socialNetwork.urlFacebook);
        }

        get getUrlInstagram(): string
        {
            return this.translateValue(this.$tStore.state.configState.setting.socialNetwork.urlInstagram);
        }

        get getUrlPinterest(): string
        {
            return this.translateValue(this.$tStore.state.configState.setting.socialNetwork.urlPinterest);
        }

        get getUrlTwitter(): string
        {
            return this.translateValue(this.$tStore.state.configState.setting.socialNetwork.urlTwitter);
        }

        onClickSocialNetwork(url: string)
        {
            window.open(url, "_blank");
        }
    }
