export enum CallbackPasswordConfirmationModalEnum
{
    UpdateProfilePax = "CallbackUpdateProfilePax",
    UpdatePassword = "CallbackUpdatePassword",
    UpdateEmail = "CallbackUpdateEmail",
    updateProfileCustomer = "updateProfileCustomer"
}

export enum CurrencyPositionEnum
{
    Right = 0,
    Left = 1
}

export enum ErrorServerCodeEnum
{
    Undefined = 0,
    CommunicationErrorWithPCVWeb = 50200,
    ActionFailed = 50001,
    UnableToAddPayment = 50005,
    PaymentVoidButUnableToUpdatePayment = 50006,
    AuthIsRequired = 40101,
    AuthTokenInvalid = 40102,
    UnableToKnowIfAuthIsRequired = 40103,
    AuthTokenTypeInvalid = 40104,
    CustomerTokenInvalid = 40105,
    CustomerTokenExpired = 40106,
    UserCreateEmailAlreadyUsed = 40001,
    UserCreatePaxAlreadyExist = 40002,
    UserSignInInvalid = 40003,
    UserRecoverPasswordEmailInvalid = 40004,
    UserUpdatePasswordInvalid = 40005,
    ReservationTokenInvalid = 40107,
    ReservationTokenExpired = 40108,
    ResetPasswordEmailInvalid = 40006
}

export enum PaxFormEnum
{
    LastName = 0,
    FirstName = 1,
    Gender = 2,
    Telephone = 3,
    Email = 4,
    Address = 5,
    City = 6,
    Province = 7,
    Postal = 8,
    Birthdate = 9,
    Allergies = 10,
    TelephoneWork = 11,
    TelephoneWorkExt = 12,
    SpecialRequest = 13,
}

export enum PaymentStatusEnum
{
    Active = 1,
    Cancelled = 2,
    Failed = 3,
}

export enum ProductDisplayModeEnum
{
    Public = 0,
    Private = 1,
    Test = 2
}

export enum ProductUnitEnum
{
    PerPax = 0,
    PerRoom = 1,
    PerPaxPerDay = 2,
    PerRoomPerDay = 3,
    PerQuantity = 4,
}

export enum RezToursLanguageEnum
{
    accepted = "accepted",
    accountAccessEmail = "accountAccessEmail",
    addPayment = "addPayment",
    address = "address",
    adults = "adults",
    allergies = "allergies",
    amount = "amount",
    asteriskFieldMandatory = "asteriskFieldMandatory",
    authRegisterMsg = "authRegisterMsg",
    availableRooms = "availableRooms",
    balanceDue = "balanceDue",
    bookingConfirmed = "bookingConfirmed",
    cancel = "cancel",
    canceled = "canceled",
    canceledPaymentMsg = "canceledPaymentMsg",
    canceledPaymentMsgError = "canceledPaymentMsgError",
    canceledPaymentMsgInProgress = "canceledPaymentMsgInProgress",
    canceledReservationMsg = "canceledReservationMsg",
    categories = "categories",
    category = "category",
    children = "children",
    city = "city",
    class = "class",
    classes = "classes",
    clickHere = "clickHere",
    close = "close",
    code = "code",
    commission = "commission",
    commissionTaxesGST = "commissionTaxesGST",
    commissionTaxesPST = "commissionTaxesPST",
    confirm = "confirm",
    confirmation = "confirmation",
    confirmCurrentPassword = "confirmCurrentPassword",
    confirmNewPassword = "confirmNewPassword",
    confirmPassword = "confirmPassword",
    confirmPasswordInvalid = "confirmPasswordInvalid",
    confirmReservation = "confirmReservation",
    congratulationsRegistered = "congratulationsRegistered",
    connection = "connection",
    contactAndAddress = "contactAndAddress",
    contactFirstName = "contactFirstName",
    contactLastName = "contactLastName",
    contactTitle = "contactTitle",
    copyright = "copyright",
    creditCard = "creditCard",
    custom = "custom",
    dateOfBirth = "dateOfBirth",
    days = "days",
    declined = "declined",
    departure = "departure",
    displayModeTest = "displayModeTest",
    duration = "duration",
    email = "email",
    emailInvalid = "emailInvalid",
    enterEmail = "enterEmail",
    errorAuthCreate = "errorAuthCreate",
    errorAuthEmailAlreayUsed = "errorAuthEmailAlreayUsed",
    errorAuthPasswordInvalid = "errorAuthPasswordInvalid",
    errorAuthPaxAlreayExist = "errorAuthPaxAlreayExist",
    errorAuthUpdate = "errorAuthUpdate",
    errorCommPCVWeb = "errorCommPCVWeb",
    errorCustomerTokenInvalid = "errorCustomerTokenInvalid",
    errorRecoverPasswordEmailInvalid = "errorRecoverPasswordEmailInvalid",
    errorSignInInvalid = "errorSignInInvalid",
    errorUnableToSendEmail = "errorUnableToSendEmail",
    file = "file",
    filterDateFrom = "filterDateFrom",
    filterDestinationFrom = "filterDestinationFrom",
    filterDestinationTo = "filterDestinationTo",
    filterDuration = "filterDuration",
    firstName = "firstName",
    flyer = "flyer",
    followUs = "followUs",
    forgotPassword = "forgotPassword",
    forgotPasswordSuccess = "forgotPasswordSuccess",
    getBooking = "getBooking",
    getInvoice = "getInvoice",
    goodbyeLogOut = "goodbyeLogOut",
    home = "home",
    iAccept = "iAccept",
    information = "information",
    invoice = "invoice",
    isInvalid = "isInvalid",
    isRequired = "isRequired",
    languageCode = "languageCode",
    languageName = "languageName",
    lastName = "lastName",
    learnMore = "learnMore",
    logIn = "logIn",
    logOut = "logOut",
    messageChildrenManyRooms = "messageChildrenManyRooms",
    messageNotEnoughInventory = "messageNotEnoughInventory",
    minimumDeposit = "minimumDeposit",
    moreInfo = "moreInfo",
    multiplePayments = "multiplePayments",
    myAccount = "myAccount",
    name = "name",
    nbAdult = "nbAdult",
    nbChild = "nbChild",
    nbRoom = "nbRoom",
    newPassword = "newPassword",
    next = "next",
    nights = "nights",
    no = "no",
    noBalanceDue = "noBalanceDue",
    noProductsAvailable = "noProductsAvailable",
    notAvailableDemo = "notAvailableDemo",
    notAvailableTest = "notAvailableTest",
    occupancy = "occupancy",
    otherDates = "otherDates",
    package = "package",
    passenger = "passenger",
    passengers = "passengers",
    password = "password",
    passwordInvalid = "passwordInvalid",
    pay = "pay",
    paymentIsNumber = "paymentIsNumber",
    paymentNotNegative = "paymentNotNegative",
    payments = "payments",
    paymentsDone = "paymentsDone",
    personalInformations = "personalInformations",
    pleaseLogIntoCustomerArea = "pleaseLogIntoCustomerArea",
    postal = "postal",
    previous = "previous",
    price = "price",
    priceDetail = "priceDetail",
    pricePerPerson = "pricePerPerson",
    print = "print",
    project = "project",
    productOptions = "productOptions",
    productUnavailable = "productUnavailable",
    profileUpdateSuccess = "profileUpdateSuccess",
    prospectLink = "prospectLink",
    prospectMessage1 = "prospectMessage1",
    prospectMessage2 = "prospectMessage2",
    province = "province",
    quantity = "quantity",
    referenceNumber = "referenceNumber",
    register = "register",
    registration = "registration",
    regularPriceProvider = "regularPriceProvider",
    reservationExpiredMsg = "reservationExpiredMsg",
    reservationExpiredTitle = "reservationExpiredTitle",
    resetPasswordClient = "resetPasswordClient",
    resetPasswordLinkInvalid = "resetPasswordLinkInvalid",
    resetPasswordSuccess = "resetPasswordSuccess",
    return = "return",
    room = "room",
    rooms = "rooms",
    sameAddress = "sameAddress",
    seeMore = "seeMore",
    seeMoreOffers = "seeMoreOffers",
    seeOffer = "seeOffer",
    serverErrorMsg = "serverErrorMsg",
    serverErrorTitle = "serverErrorTitle",
    soldOut = "soldOut",
    specialRequest = "specialRequest",
    startingFrom = "startingFrom",
    status = "status",
    taxes = "taxes",
    taxesFeesIncluded = "taxesFeesIncluded",
    telephone = "telephone",
    termsOfUse = "termsOfUse",
    termsOfUseError = "termsOfUseError",
    textCharactersLimit = "textCharactersLimit",
    title = "title",
    total = "total",
    totalAmountPaid = "totalAmountPaid",
    totalPayment = "totalPayment",
    totalPrice = "totalPrice",
    unsupportedCookiesMessage = "unsupportedCookiesMessage",
    unsupportedCookiesTitle = "unsupportedCookiesTitle",
    update = "update",
    waitAMoment = "waitAMoment",
    welcomeLogIn = "welcomeLogIn",
    workPhone = "workPhone",
    workPhoneExtension = "workPhoneExtension",
    yes = "yes",
    youDontHaveAccount = "youDontHaveAccount",
    authRegisterMsgPromo1 = "authRegisterMsgPromo1",
    authRegisterMsgPromo2 = "authRegisterMsgPromo2",
    authRegisterMsgPromo3 = "authRegisterMsgPromo3",
    sexM = "sexM",
    sexF = "sexF",
    profile = "profile",
    sessionTimeOutTitle = "sessionTimeOutTitle",
    sessionTimeOutMsg = "sessionTimeOutMsg",
    perPassenger = "perPassenger",
    perRoom = "perRoom",
    perPaxPerDay = "perPaxPerDay",
    perRoomPerDay = "perRoomPerDay",
    totalOptions = "totalOptions",
    packages = "packages",
    options = "options",
    optionsFees = "optionsFees",
    optionalProducts = "optionalProducts",
    pickupPoint = "pickupPoint"
}