
    import { Component, Vue } from "vue-property-decorator";

    @Component
    export default class SocialNetworkFooter extends Vue
    {

        private SocialNetworkHover: SocialNetworkEnum = SocialNetworkEnum.None;

        get getUrlFacebook(): string
        {
            return this.translateValue(this.$tStore.state.configState.setting.socialNetwork.urlFacebook);
        }

        get getUrlInstagram(): string
        {
            return this.translateValue(this.$tStore.state.configState.setting.socialNetwork.urlInstagram);
        }

        get getUrlPinterest(): string
        {
            return this.translateValue(this.$tStore.state.configState.setting.socialNetwork.urlPinterest);
        }

        get getUrlTwitter(): string
        {
            return this.translateValue(this.$tStore.state.configState.setting.socialNetwork.urlTwitter);
        }

        get getSocialNetworkEnum() : typeof SocialNetworkEnum
        {
            return SocialNetworkEnum;
        }

        onClickSocialNetwork(url: string)
        {
            window.open(url, "_blank");
        }

        isAnotherSocialNetworkHover(socialNetwork: SocialNetworkEnum)
        {
            if (this.SocialNetworkHover !== SocialNetworkEnum.None && this.SocialNetworkHover !== socialNetwork)
            {
                return "hoverGroupIcons";
            }
            else
            {
                return "";
            }
        }

        onMouseOverSocialNetwork(socialNetwork: SocialNetworkEnum)
        {
            this.SocialNetworkHover = socialNetwork;
        }

        onMouseLeaveSocialNetwork()
        {
            this.SocialNetworkHover = SocialNetworkEnum.None;
        }

    }

    enum SocialNetworkEnum {
        None = 0,
        Facebook = 1,
        Instagram = 2,
        Pinterest = 3,
        Twitter = 4
    }

