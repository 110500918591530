import Vue from "vue";
import { MutationTree } from "vuex";
import ReservationState from "./types";
import MutationsEnum from "@/store/storeEnum/mutationsEnum";
import ReservationPayload from "./models";

export const mutations: MutationTree<ReservationState> =
{
    [MutationsEnum.SetReservationDetail](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationDetail)
    {
        p_State.detail = p_Payload.detail;
    },

    [MutationsEnum.SetReservationPaxLastName](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxLastName)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].lastName = p_Payload.lastName;
    },

    [MutationsEnum.SetReservationPaxFirstName](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxFirstName)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].firstName = p_Payload.firstName;
    },

    [MutationsEnum.SetReservationPaxTelephone](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxTelephone)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].tel1 = p_Payload.telephone;
    },

    [MutationsEnum.SetReservationPaxLanguage](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxLanguage)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].lang = p_Payload.language;
    },

    [MutationsEnum.SetReservationPaxEmail](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxEmail)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].email = p_Payload.email;
    },

    [MutationsEnum.SetReservationPaxAddress](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxAddress)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].address = p_Payload.address;
    },

    [MutationsEnum.SetReservationPaxCity](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxCity)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].city = p_Payload.city;
    },

    [MutationsEnum.SetReservationPaxProvince](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxProvince)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].province = p_Payload.province;
    },

    [MutationsEnum.SetReservationPaxPostal](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxPostal)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].postal = p_Payload.postal;
    },

    [MutationsEnum.SetReservationPaxGender](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxGender)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].gender = p_Payload.gender;
    },

    [MutationsEnum.SetReservationPaxBirthdate](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxBirthdate)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].birthDate = p_Payload.birthdate;
    },

    [MutationsEnum.SetReservationPaxAllergies](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxAllergies)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].allergies = p_Payload.allergies;
    },

    [MutationsEnum.SetReservationPaxSpecialRequest](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxSpecialRequest)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].specialRequest = p_Payload.specialRequest;
    },

    [MutationsEnum.SetReservationPaxTelephoneWork](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxTelephoneWork)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].tel2 = p_Payload.telephoneWork;
    },

    [MutationsEnum.SetReservationPaxTelephoneWorkExt](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxTelephoneWorkExt)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].telExt = p_Payload.telephoneWorkExt;
    },

    [MutationsEnum.SetReservationPaxSameAddress](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationPaxSameAddress)
    {
        p_State.detail.listRoom[p_Payload.indexRoom].listPax[p_Payload.indexPax].useSameAddress = p_Payload.useSameAddress;
    },

    [MutationsEnum.SetReservationBackgroundImagePath](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetReservationBackgroundImagePath)
    {
        p_State.backgroundImagePath = p_Payload.backgroundImagePath;
    },

    [MutationsEnum.UpdateReservationPaymentStatus](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.UpdateReservationPaymentStatus)
    {
        let payment = p_State.detail.listPayment.find((payment) =>
        {
            return payment.index === p_Payload.paymentIndex;
        });

        if (payment)
        {
            payment.status = p_Payload.newStatus;
        }
    },

    [MutationsEnum.SetRedirect](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetRedirect)
    {
        p_State.redirect = true;
    },

    [MutationsEnum.SetInvoice](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetInvoice)
    {
        p_State.invoice.file = p_Payload.model.file;
        p_State.invoice.invoice = p_Payload.model.invoice;
    },

    [MutationsEnum.SetShowModalCancelReservation](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetShowModalCancelReservation)
    {
        p_State.showModalCancelReservation = p_Payload.value;
    },

    [MutationsEnum.SetLayoutLoading](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetLayoutLoading)
    {
        p_State.layoutLoading = p_Payload.value;
    },

    [MutationsEnum.SetIntervalTimer](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetIntervalTimer)
    {
        p_State.intervalTimer = p_Payload.value;
    },

    [MutationsEnum.ClearIntervalTimer](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.ClearIntervalTimer)
    {
        clearInterval(p_State.intervalTimer);
    },

    [MutationsEnum.SetModalCancelReservationLogOut](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetModalCancelReservationLogOut)
    {
        p_State.modalCancelReservationLogOut = p_Payload.value;
    },

    [MutationsEnum.SetModalCancelReservationRedirect](p_State: ReservationState, p_Payload: ReservationPayload.Mutations.SetModalCancelReservationRedirect)
    {
        p_State.modalCancelReservationRedirect = p_Payload.value;
    }
};