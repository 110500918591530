import Vue from "vue";
import { ActionTree } from "vuex";
import ReservationState from "./types";
import { RootState } from "@/store/types";
import ActionsEnum from "@/store/storeEnum/actionsEnum";
import ReservationPayload from "./models";
import ReservationTokenModel from "@/models/reservationTokenModel";
import CustomerProject from "@/models/customerProject";
import { ProductDetailImageModel, ProductImageQuery } from "@/apiManager/product/productData";
import { ReservationBody, ReservationCreateModel, ReservationModel, ReservationQuery } from "@/apiManager/reservation/reservationData";
import { PaxUpdateBody, PaxUpdateBodyPax } from "@/apiManager/pax/paxData";
import { PaymentCancelBody, PaymentURLModel } from "@/apiManager/payment/paymentData";
import { InvoiceCreateBody, InvoiceCreateModel } from "@/apiManager/invoice/invoiceData";
import { PaymentStatusEnum } from "@/apiManager/_common/enums";

export const actions: ActionTree<ReservationState, RootState> =
{
    async [ActionsEnum.FetchReservation]({ commit, state }, p_Payload: ReservationPayload.Actions.FetchReservation)
    {
        try
        {
            let token: string = new ReservationTokenModel().raw;

            let queryReservation: ReservationQuery = new ReservationQuery(p_Payload.reservCode, token);
            let data: ReservationModel = await Vue.$apiManager.reservation.getDetailAsync(queryReservation);

            commit(new ReservationPayload.Mutations.SetReservationDetail(data));

            if (state.backgroundImagePath.length === 0)
            {
                let queryImage: ProductImageQuery = new ProductImageQuery(data.mainProduct.code);
                let listImage: ProductDetailImageModel[] = await Vue.$apiManager.product.listImageAsync(queryImage);
                if (listImage.length > 0)
                {
                    commit( new ReservationPayload.Mutations.SetReservationBackgroundImagePath(listImage[0].pathImage));
                }
            }

            return data;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    },
    async [ActionsEnum.CreateReservation]({ commit }, p_Payload: ReservationPayload.Actions.CreateReservation): Promise<string>
    {
        try
        {
            let body: ReservationBody = new ReservationBody();
            body.product = p_Payload.product;
            body.productPriceDateCode = p_Payload.productPriceDateCode;
            body.productPriceClassCode = p_Payload.productPriceClassCode;
            body.productPriceCategoryCode = p_Payload.productPriceCategoryCode;
            body.pricingClassCode = p_Payload.pricingClassCode;
            body.pricingCategoryCode = p_Payload.pricingCategoryCode;
            body.dateDepart = p_Payload.dateDepart;
            body.dateReturn = p_Payload.dateReturn;
            body.nbAdult = p_Payload.nbAdult;
            body.nbChild = p_Payload.nbChild;
            body.nbRoom = p_Payload.nbRoom;
            body.busPickup = p_Payload.busPickup;
            body.project = CustomerProject.Get();
            body.listOption = p_Payload.listOption;

            let data: ReservationCreateModel = await Vue.$apiManager.reservation.createAsync(body);

            let reservationToken: ReservationTokenModel = new ReservationTokenModel(data.token);

            return reservationToken.reservationCode;
        }
        catch (ex)
        {
            throw ex;
        }
    },
    async [ActionsEnum.DeleteReservation]({ commit }, p_Payload: ReservationPayload.Actions.DeleteReservation)
    {
        try
        {
            let token: string = new ReservationTokenModel().raw;

            let query: ReservationQuery = new ReservationQuery(p_Payload.reservCode, token);
            let promise = await Vue.$apiManager.reservation.deleteAsync(query);

            commit(new ReservationPayload.Mutations.SetReservationDetail(new ReservationModel()));
            ReservationTokenModel.delete();

            return promise;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    },
    async [ActionsEnum.SetPassengerReservation]({ state }, p_Payload: ReservationPayload.Actions.SetPassengerReservation)
    {
        try
        {
            let token: string = new ReservationTokenModel().raw;
            let body: PaxUpdateBody = new PaxUpdateBody(state.detail.code, token);

            state.detail.listRoom.forEach((room, indexRoom) =>
            {
                room.listPax.forEach((roomPax, indexPax) =>
                {
                    body.listPax.push(new PaxUpdateBodyPax(roomPax));
                });
            });

            let promise = await Vue.$apiManager.pax.updateListPaxAsync(body);
            return promise;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    },
    async [ActionsEnum.CreateInvoice]({ state, commit }, p_Payload: ReservationPayload.Actions.CreateInvoice)
    {
        try
        {
            let token: string = new ReservationTokenModel().raw;

            let body: InvoiceCreateBody = new InvoiceCreateBody(state.detail.code, token, p_Payload.language, p_Payload.isPaymentDisabled);
            let model: InvoiceCreateModel = await Vue.$apiManager.invoice.createAsync(body);
            commit(new ReservationPayload.Mutations.SetInvoice(model));
            return model;
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    },
    async [ActionsEnum.CancelPayment]({ state, commit }, p_Payload: ReservationPayload.Actions.CancelPayment)
    {
        try
        {
            let token: string = new ReservationTokenModel().raw;

            let body: PaymentCancelBody = new PaymentCancelBody();
            body.index = p_Payload.paymentIndex;
            body.reservation = p_Payload.reservCode;
            body.token = token;

            await Vue.$apiManager.payment.cancelAsync(body);
            commit(new ReservationPayload.Mutations.UpdateReservationPaymentStatus(p_Payload.paymentIndex, PaymentStatusEnum.Cancelled));
        }
        catch (ex)
        {
            return Promise.reject(ex);
        }
    }
};