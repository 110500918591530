enum MutationsEnum
{
    //Config
    SetSetting = "SetSetting",
    SetSettingsIsLoading = "SetSettingsIsLoading",
    SetSettingUseMainLanguage = "SetSettingUseMainLanguage",

    //ModalInformative
    DisplayModalInformative = "DisplayModalInformative",
    HideModalInformative = "HideModalInformative",

    //AuthenticationModal
    DisplayAuthenticationModal = "DisplayAuthenticationModal",
    HideAuthenticationModal = "HideAuthenticationModal",
    SetIsAuth = "SetIsAuth",
    SetWelcomeComponent = "SetWelcomeComponent",
    SetRedirectAfterRegistration = "SetRedirectAfterRegistration",
    SetVisibleCustomerProjectsModal = "SetVisibleCorporateAccountsDepartementModal",

    //Product
    SetProductGroups = "SetProductGroups",
    SetProductGroupsIsLoading = "SetProductGroupsIsLoading",
    SetProductDetail = "SetProductDetail",
    SetProductDetailIsLoading = "SetProductDetailIsLoading",
    SetOriginList = "SetOriginList",
    SetDestinationList = "SetDestinationList",
    SetProductDates = "SetProductDates",
    SetProductDatesIsLoading = "SetProductDatesIsLoading",
    SetProductDetailSelection = "SetProductDetailSelection",

    //TravelBag
    SetTravelBagDetail = "SetTravelBagDetail",

    //Reservation
    SetReservationDetail = "SetReservationDetail",
    SetReservationPaxLastName = "SetReservationPaxLastName",
    SetReservationPaxFirstName = "SetReservationPaxFirstName",
    SetReservationPaxTelephone = "SetReservationPaxTelephone",
    SetReservationPaxLanguage = "SetReservationPaxLanguage",
    SetReservationPaxEmail = "SetReservationPaxEmail",
    SetReservationPaxAddress = "SetReservationPaxAddress",
    SetReservationPaxCity = "SetReservationPaxCity",
    SetReservationPaxProvince = "SetReservationPaxProvince",
    SetReservationPaxPostal = "SetReservationPaxPostal",

    SetReservationPaxGender = "SetReservationPaxGender",
    SetReservationPaxBirthdate = "SetReservationPaxBirthdate",
    SetReservationPaxAllergies = "SetReservationPaxAllergies",
    SetReservationPaxSpecialRequest = "SetReservationPaxSpecialRequest",
    SetReservationPaxTelephoneWork = "SetReservationPaxTelephoneWork",
    SetReservationPaxTelephoneWorkExt = "SetReservationPaxTelephoneWorkExt",

    SetReservationPaxSameAddress = "SetReservationPaxSameAddress",
    SetReservationBackgroundImagePath = "SetReservationBackgroundImagePath",
    UpdateReservationPaymentStatus = "UpdateReservationPaymentStatus",
    SetRedirect = "SetRedirect",
    SetInvoice = "SetInvoice",
    SetShowModalCancelReservation = "SetShowModalCancelReservation",
    SetLayoutLoading = "SetLayoutLoading",
    SetIntervalTimer = "SetIntervalTimer",
    ClearIntervalTimer = "ClearIntervalTimer",

    SetModalCancelReservationLogOut = "SetModalCancelReservationLogOut",
    SetModalCancelReservationRedirect = "SetModalCancelReservationRedirect"
}

export default MutationsEnum;