import { ProductDisplayModeEnum, ProductUnitEnum } from "../_common/enums";
import LanguageModel from "../_common/languageModel";

//#region Query

export class ProductDateQuery
{
    productCode: string;

    constructor(p_ProductCode: string)
    {
        this.productCode = p_ProductCode;
    }
}

export class ProductImageQuery
{
    productCode: string;

    constructor(p_ProductCode: string)
    {
        this.productCode = p_ProductCode;
    }
}

export class ProductDetailQuery
{
    dateCode: number;
    dateFrom: string;
    dateTo: string;
    productCode: string;

    constructor(p_ProductCode: string, p_DateFrom: string, p_DateTo: string, p_DateCode: number)
    {
        this.dateCode = p_DateCode;
        this.dateFrom = p_DateFrom;
        this.dateTo = p_DateTo;
        this.productCode = p_ProductCode;
    }
}

export class ProductOptionQuery
{
    dateFrom: string;
    dateTo: string;
    pricingCategory: number;
    pricingClass: number;
    productCode: string;
    nbPax: number;
    nbRoom: number;

    constructor(p_ProductCode: string, p_DateFrom: string, p_DateTo: string, p_PricingClass: number, p_PricingCategory: number, p_NbPax: number, p_NbRoom: number)
    {
        this.dateFrom = p_DateFrom;
        this.dateTo = p_DateTo;
        this.pricingCategory = p_PricingCategory;
        this.pricingClass = p_PricingClass;
        this.productCode = p_ProductCode;
        this.nbPax = p_NbPax;
        this.nbRoom = p_NbRoom;
    }
}

//#endregion

//#region Model

export class ProductDateModel
{
    uniqueID: number = 0;

    dateDepart: string = "";
    dateReturn: string = "";
    duration: number = 0;
    isAvailable: boolean = false;
    pricing: ProductDatePricingModel = new ProductDatePricingModel();
    productPriceDateCode: number = 0
}

export class ProductDatePricingModel
{
    currency: string = "";
    price: number = 0;
    pricingCategoryName: LanguageModel = new LanguageModel();
    pricingClassName: LanguageModel = new LanguageModel();
    taxes: number = 0;
    total: number = 0;
}

export class ProductDetailModel
{
    product: string = "";

    dateFrom: string = "";
    dateTo: string = "";
    description: LanguageModel = new LanguageModel();
    displayMode: ProductDisplayModeEnum = ProductDisplayModeEnum.Public;
    duration: number = 0;
    flyer: LanguageModel = new LanguageModel();
    isDurationInNights: boolean = false;
    listBusPickup: ProductDetailBusPickupModel[] = [];
    listClass: ProductDetailProductPriceClassModel[] = [];
    listImage: ProductDetailImageModel[] = [];
    listProductGroup: ProductDetailProductGroupModel[] = [];
    listSection: ProductDetailSectionModel[] = [];
    moreInfo: LanguageModel = new LanguageModel();
    name: LanguageModel = new LanguageModel();
    productPriceDateCode: number = 0;
}

export class ProductDetailBusPickupModel
{
    code: number = 0;

    name: string = "";
    time: string = "";
}

export class ProductDetailProductPriceClassModel
{
    code: number = 0;

    hasOption: boolean = false;
    inventory: number = 0;
    listCategory: ProductDetailProductPriceCategoryModel[] = [];
    pricingClass: ProductDetailPricingClassModel = new ProductDetailPricingClassModel();
}

export class ProductDetailProductPriceCategoryModel
{
    code: number = 0;

    deposit: number = 0;
    price: number = 0;
    taxes: number = 0;
    total: number = 0;
    pricingCategory: ProductDetailPricingCategoryModel = new ProductDetailPricingCategoryModel();
}

export class ProductDetailPricingClassModel
{
    code: number = 0;

    name: LanguageModel = new LanguageModel();
}

export class ProductDetailPricingCategoryModel
{
    code: number = 0;

    isChild: boolean = false;
    name: LanguageModel = new LanguageModel();
    nbPax: number = 0;
}

export class ProductDetailImageModel
{
    code: number = 0;

    caption: string = "";
    pathImage: string = "";
    pathThumbnail: string = "";
}

export class ProductDetailProductGroupModel
{
    code: number = 0;

    name: LanguageModel = new LanguageModel();
}

export class ProductDetailSectionModel
{
    content: LanguageModel = new LanguageModel();
    title: LanguageModel = new LanguageModel();
}

export class ProductOptionModel
{
    code: number = 0;

    availability: number = 0;
    currency: string = "";
    deposit: number = 0;
    description: LanguageModel = new LanguageModel();
    hasInventory: boolean = false;
    isTours: boolean = false;
    name: LanguageModel = new LanguageModel();
    nbRequested: number = 0;
    pricingCategory: LanguageModel = new LanguageModel();
    pricingClass: LanguageModel = new LanguageModel();
    price: number = 0;
    product: string = "";
    quantity: number = 0;
    taxes: number = 0;
    total: number = 0;
    unit: ProductUnitEnum = ProductUnitEnum.PerPax;
}

//#endregion