import { ReservationRoomPaxModel } from "../reservation/reservationData";

//#region Query

export class PaxUpdateBody
{
    reservationCode: string = "";
    token: string = "";
    listPax: PaxUpdateBodyPax[] = [];

    constructor(p_ReservationCode: string, p_Token: string)
    {
        this.reservationCode = p_ReservationCode;
        this.token = p_Token;
        this.listPax = [];
    }
}

export class PaxUpdateBodyPax
{
    index: number = 0;

    address: string = "";
    allergies: string = "";
    birthDate: string = "";
    city: string = "";
    email: string = "";
    firstName: string = "";
    gender: string = "";
    lang: string = "EN";
    lastName: string = "";
    postal: string = "";
    province: string = "";
    specialRequest: string = "";
    tel1: string = "";
    tel2: string = "";
    telExt: string = "";
    useSameAddress: boolean = false;

    constructor(p_Model: ReservationRoomPaxModel)
    {
        this.index = p_Model.index;
        this.address = p_Model.address;
        this.allergies = p_Model.allergies;
        this.birthDate = p_Model.birthDate;
        this.city = p_Model.city;
        this.email = p_Model.email;
        this.firstName = p_Model.firstName;
        this.gender = p_Model.gender;
        this.lang = p_Model.lang;
        this.lastName = p_Model.lastName;
        this.postal = p_Model.postal;
        this.province = p_Model.province;
        this.specialRequest = p_Model.specialRequest;
        this.tel1 = p_Model.tel1;
        this.tel2 = p_Model.tel2;
        this.telExt = p_Model.telExt;
        this.useSameAddress = p_Model.useSameAddress;
    }
}

//#endregion